import React from 'react';
import { Link } from 'react-router-dom';
import KismetLogo from './kismetlogo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXTwitter, faDiscord } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-dark-500/60 backdrop-blur-sm border-t border-dark-300">
      <div className="max-w-7xl mx-auto px-3 sm:px-5 lg:px-12">
        <div className="py-12">
          {/* Main Footer Content */}
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            {/* Logo and Description */}
            <div className="col-span-1 md:col-span-2">
              <Link to="/">
                <KismetLogo />
              </Link>
              <p className="mt-4 text-secondary text-sm">
                Kismet DAO - Gamified DAO system building tools for the future.
              </p>
              {/* Social Links */}
              <div className="flex space-x-6 mt-6">
                <a 
                  href="https://x.com/kismetmoney" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="text-secondary hover:text-primary transition-colors"
                >
                  <FontAwesomeIcon icon={faXTwitter} className="h-5 w-5" />
                </a>
                <a 
                  href="https://discord.gg/taDv9uvzMd" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="text-secondary hover:text-primary transition-colors"
                >
                  <FontAwesomeIcon icon={faDiscord} className="h-5 w-5" />
                </a>
              </div>
            </div>

            {/* Empty column for spacing */}
            <div className="hidden md:block"></div>

            {/* Quick Links - Now aligned right */}
            <div className="md:text-right">
              <h3 className="text-primary font-medium mb-4">Quick Links</h3>
              <ul className="space-y-2">
                <li>
                  <Link to="/" className="text-secondary hover:text-primary text-sm transition-colors">
                    Main
                  </Link>
                </li>
                <li>
                  <Link to="/protocol" className="text-secondary hover:text-primary text-sm transition-colors">
                    Protocol
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          {/* Bottom Bar */}
          <div className="mt-12 pt-8 border-t border-dark-300">
            <div className="flex flex-col md:flex-row justify-between items-center">
              <p className="text-secondary text-sm">
                © {currentYear} Kismet DAO. All rights reserved.
              </p>
              <div className="flex space-x-6 mt-4 md:mt-0">
                <Link to="/privacy" className="text-secondary hover:text-primary text-sm transition-colors">
                  Privacy Policy
                </Link>
                <Link to="/terms" className="text-secondary hover:text-primary text-sm transition-colors">
                  Terms of Service
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;