import * as React from 'react';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { 
  Menu, 
  X, 
  Wallet, 
  ExternalLink,
  Home,
  GitBranch,
  FileText,
  ChevronDown
} from 'lucide-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXTwitter, faDiscord } from '@fortawesome/free-brands-svg-icons';
import KismetLogo from './kismetlogo';

const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isLegalOpen, setIsLegalOpen] = useState(false);
    const location = useLocation();

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const toggleLegal = () => {
        setIsLegalOpen(!isLegalOpen);
    };

    const isActive = (path: string) => {
        return location.pathname === path ? 'text-primary' : 'text-secondary';
    };

    const ConnectButton = ({ className = "" }: { className?: string }) => (
        <button 
            disabled
            className={`group relative flex items-center justify-center px-4 sm:px-6 py-2 bg-gray-500/20 rounded-full
                     transition-all cursor-not-allowed opacity-50
                     min-w-[140px] sm:min-w-[160px] ${className}`}
        >
            <svg className="absolute inset-0 w-full h-full" preserveAspectRatio="none" viewBox="0 0 200 40">
                <rect
                    x="1" y="1"
                    width="198" height="38"
                    rx="20"
                    className="stroke-current text-gray-500/30 fill-none"
                    strokeWidth="1"
                />
                <rect
                    x="2" y="2"
                    width="196" height="36"
                    rx="19"
                    className="stroke-current text-gray-500/20 fill-none"
                    strokeWidth="0.5"
                />
            </svg>
            <div className="relative flex items-center justify-center gap-1 sm:gap-2">
                <Wallet className="h-3 w-3 sm:h-4 sm:w-4 text-gray-400" />
                <span className="text-gray-400 font-medium tracking-wider text-xs sm:text-sm whitespace-nowrap">
                    Coming Soon
                </span>
            </div>
        </button>
    );

    return (
        <nav className="fixed top-0 left-0 right-0 z-50 bg-dark-500/60 backdrop-blur-sm border-b border-dark-300">
            <div className="max-w-7xl mx-auto px-3 sm:px-5 lg:px-12">
                <div className="flex items-center justify-between h-14 lg:h-16">
                    <div className="flex-shrink-0 flex items-center">
                        <Link to="/" className="block  h-6 lg:h-8 w-auto">
                            <KismetLogo />
                        </Link>
                    </div>

                    {/* Desktop Navigation - No Icons */}
                    <div className="hidden md:flex md:items-center md:space-x-4 lg:space-x-8">
                        <Link 
                            to="/" 
                            className={`${isActive('/')} hover:text-primary px-2 lg:px-3 py-2 text-sm font-medium transition-colors`}
                        >
                            Main
                        </Link>
                        <Link 
                            to="/protocol" 
                            className={`${isActive('/protocol')} hover:text-primary px-2 lg:px-3 py-2 text-sm font-medium transition-colors`}
                        >
                            Protocol
                        </Link>
                        <a 
                            href="https://runemine.com" 
                            target="_blank" 
                            rel="noopener noreferrer"
                            className="flex items-center gap-1 hover:text-primary px-2 lg:px-3 py-2 text-sm font-medium transition-colors text-secondary"
                        >
                            Bridge
                            <ExternalLink className="w-3 h-3 lg:w-4 lg:h-4 text-blue-200 transition-transform group-hover:translate-x-1" />
                        </a> 
                        <ConnectButton className="ml-2" />
                    </div>

                    <div className="flex md:hidden items-center gap-2">
                        <ConnectButton />
                        <button
                            onClick={toggleMenu}
                            className="text-secondary hover:text-primary p-2"
                        >
                            {isMenuOpen ? (
                                <X className="h-6 w-6" />
                            ) : (
                                <Menu className="h-6 w-6" />
                            )}
                        </button>
                    </div>
                </div>
            </div>

            {/* Mobile Navigation with Icons */}
            {isMenuOpen && (
                <div className="md:hidden">
                    <div className="px-2 mt-2 pb-2 space-y-1 bg-dark-500/95 backdrop-blur-sm">
                        <Link 
                            to="/" 
                            className={`${isActive('/')} hover:text-primary flex items-center gap-3 pr-3 py-4 text-base font-medium border-b border-dark-300`}
                            onClick={() => setIsMenuOpen(false)}
                        >
                            <Home className="w-5 h-5" />
                            <span>Main</span>
                        </Link>
                        <Link 
                            to="/protocol" 
                            className={`${isActive('/protocol')} hover:text-primary flex items-center gap-3 pr-3 py-4 text-base font-medium border-b border-dark-300`}
                            onClick={() => setIsMenuOpen(false)}
                        >
                            <GitBranch className="w-5 h-5" />
                            <span>Protocol</span>
                        </Link>
                        <a 
                            href="https://runemine.com" 
                            target="_blank" 
                            rel="noopener noreferrer"
                            className="flex items-center gap-3 hover:text-primary pr-3 py-4 text-base font-medium text-secondary border-b border-dark-300"
                            onClick={() => setIsMenuOpen(false)}
                        >
                            <ExternalLink className="w-5 h-5" />
                            <span>Bridge</span>
                        </a>

                        {/* Legal Section Toggle */}
                        <button 
                            onClick={toggleLegal}
                            className="w-full flex items-center justify-between pr-3 py-4 text-base font-medium text-secondary hover:text-primary border-b border-dark-300"
                        >
                            <div className="flex items-center gap-3">
                                <FileText className="w-5 h-5" />
                                <span>Legal</span>
                            </div>
                            <ChevronDown className={`w-5 h-5 transition-transform ${isLegalOpen ? 'rotate-180' : ''}`} />
                        </button>

                        {/* Expandable Legal Links */}
                        {isLegalOpen && (
                            <div className="pl-11 space-y-2 py-2 border-b border-dark-300">
                                <Link 
                                    to="/privacy" 
                                    className="block py-2 text-sm text-secondary hover:text-primary"
                                    onClick={() => setIsMenuOpen(false)}
                                >
                                    Privacy Policy
                                </Link>
                                <Link 
                                    to="/terms" 
                                    className="block py-2 text-sm text-secondary hover:text-primary"
                                    onClick={() => setIsMenuOpen(false)}
                                >
                                    Terms of Service
                                </Link>
                            </div>
                        )}

                        {/* Social Links */}
                        <div className="flex items-center gap-6 px-3 py-2">
                            <a 
                                href="https://x.com/kismetmoney" 
                                target="_blank" 
                                rel="noopener noreferrer" 
                                className="text-secondary hover:text-primary transition-colors"
                            >
                                <FontAwesomeIcon icon={faXTwitter} className="h-5 w-5" />
                            </a>
                            <a 
                                href="https://discord.gg/taDv9uvzMd" 
                                target="_blank" 
                                rel="noopener noreferrer" 
                                className="text-secondary hover:text-primary transition-colors"
                            >
                                <FontAwesomeIcon icon={faDiscord} className="h-6 w-6" />
                            </a>
                        </div>
                    </div>
                </div>
            )}
        </nav>
    );
};

export default Navbar;