import React, {lazy} from 'react';
import { hydrateRoot, createRoot } from 'react-dom/client';
import './index.css';
import App from './App';

const rootElement = document.getElementById('root');

if (!rootElement) throw new Error('Failed to find the root element');

// Check if the app was pre-rendered
if (rootElement.hasAttribute('data-ssr')) {
  hydrateRoot(
    rootElement,
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
} else {
  createRoot(rootElement).render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}

// Add performance monitoring
const reportWebVitals = (metric: any) => {
  // You can send the metrics to your analytics service here
  console.log(metric);
};

// Optional: Preload critical routes
const preloadRoutes = () => {
  // Preload Governance when user hovers over its link
  const governanceLinks = document.querySelectorAll('a[href="/protocol"]');
  governanceLinks.forEach(link => {
    link.addEventListener('mouseenter', () => {
      const Governance = lazy(() => import('./components/pages/governancediagram'));
    });
  });
};

// Run preload after initial render
window.addEventListener('load', preloadRoutes);