import React from 'react';
import { ExternalLink } from 'lucide-react';

const TradeButtons: React.FC = () => {
  return (
    <div className="flex flex-col lg:flex-row gap-4 lg:gap-6 justify-center items-center w-full max-w-4xl mx-auto p-4">
      {/* MagicEden Button */}
      <button 
        onClick={() => window.open('https://magiceden.io', '_blank')}
        className="group relative w-full sm:w-80 lg:w-64 h-14 bg-gray-900/80 rounded-lg
                   transition-all duration-300 hover:scale-105 hover:-translate-y-1
                   focus:outline-none focus:ring-2 focus:ring-purple-500/50 focus:ring-offset-2 focus:ring-offset-black"
      >
        {/* SVG Border - Now using viewBox for better scaling */}
        <svg className="absolute inset-0 w-full h-full" preserveAspectRatio="none" viewBox="0 0 256 64">
          <rect
            x="1" y="1"
            width="254" height="62"
            rx="8"
            className="stroke-current text-purple-500/30 fill-none"
            strokeWidth="1"
          />
          <rect
            x="2" y="2"
            width="252" height="60"
            rx="7"
            className="stroke-current text-fuchsia-500/20 fill-none"
            strokeWidth="0.5"
          />
          {/* Corner Accents - Adjusted for better visibility at all sizes */}
          <path d="M 2 16 L 2 8 Q 2 2 8 2 L 16 2" 
                className="stroke-current text-purple-400/50 fill-none" 
                strokeWidth="2" />
          <path d="M 240 2 L 248 2 Q 254 2 254 8 L 254 16" 
                className="stroke-current text-purple-400/50 fill-none" 
                strokeWidth="2" />
          <path d="M 2 48 L 2 56 Q 2 62 8 62 L 16 62" 
                className="stroke-current text-purple-400/50 fill-none" 
                strokeWidth="2" />
          <path d="M 240 62 L 248 62 Q 254 62 254 56 L 254 48" 
                className="stroke-current text-purple-400/50 fill-none" 
                strokeWidth="2" />
        </svg>

        <div className="relative flex items-center justify-center gap-2 sm:gap-3 h-full px-4 sm:px-8">
          <img 
            src="melogo.png"
            alt="MagicEden" 
            className="w-5 h-5 sm:w-6 sm:h-6 object-contain"
          />
          <span className="text-gray-100 font-medium tracking-wider text-xs sm:text-sm whitespace-nowrap">
            TRADE ON MAGICEDEN
          </span>
          <ExternalLink className="w-4 h-4 text-purple-300/70 transition-transform group-hover:translate-x-1" />
        </div>
      </button>

      {/* Raydium Button */}
      <button 
        onClick={() => window.open('https://raydium.io', '_blank')}
        className="group relative w-full sm:w-80 lg:w-64 h-14 bg-gray-900/80 rounded-lg
                   transition-all duration-300 hover:scale-105 hover:-translate-y-1
                   focus:outline-none focus:ring-2 focus:ring-blue-500/50 focus:ring-offset-2 focus:ring-offset-black"
      >
        {/* SVG Border - Now using viewBox for better scaling */}
        <svg className="absolute inset-0 w-full h-full" preserveAspectRatio="none" viewBox="0 0 256 64">
          <rect
            x="1" y="1"
            width="254" height="62"
            rx="8"
            className="stroke-current text-cyan-500/30 fill-none"
            strokeWidth="1"
          />
          <rect
            x="2" y="2"
            width="252" height="60"
            rx="7"
            className="stroke-current text-blue-500/20 fill-none"
            strokeWidth="0.5"
          />
          {/* Corner Accents - Adjusted for better visibility at all sizes */}
          <path d="M 2 16 L 2 8 Q 2 2 8 2 L 16 2" 
                className="stroke-current text-cyan-400/50 fill-none" 
                strokeWidth="2" />
          <path d="M 240 2 L 248 2 Q 254 2 254 8 L 254 16" 
                className="stroke-current text-cyan-400/50 fill-none" 
                strokeWidth="2" />
          <path d="M 2 48 L 2 56 Q 2 62 8 62 L 16 62" 
                className="stroke-current text-cyan-400/50 fill-none" 
                strokeWidth="2" />
          <path d="M 240 62 L 248 62 Q 254 62 254 56 L 254 48" 
                className="stroke-current text-cyan-400/50 fill-none" 
                strokeWidth="2" />
        </svg>

        <div className="relative flex items-center justify-center gap-2 sm:gap-3 h-full px-4 sm:px-8">
          <svg className="w-5 h-5 sm:w-6 sm:h-6" viewBox="0 0 29 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <linearGradient id="a" gradientUnits="userSpaceOnUse" x1="28.3168" x2="-1.73336" y1="8.19162" y2="20.2086">
              <stop offset="0" stopColor="#c200fb"/>
              <stop offset=".489658" stopColor="#3772ff"/>
              <stop offset=".489758" stopColor="#3773fe"/>
              <stop offset="1" stopColor="#5ac4be"/>
            </linearGradient>
            <g fill="url(#a)">
              <path d="m26.8625 12.281v11.4104l-12.6916 7.3261-12.69859-7.3261v-14.65937l12.69859-7.33322 9.7541 5.63441 1.4723-.84941-11.2264-6.48381-14.1709 8.18262v16.35818l14.1709 8.1826 14.171-8.1826v-13.1092z"/>
              <path d="m10.6176 23.6985h-2.12353v-7.1209h7.07843c.6697-.0074 1.3095-.2782 1.7811-.7538.4716-.4755.737-1.1176.7388-1.7874.0038-.3311-.0601-.6596-.1879-.9651-.1279-.3056-.3168-.5817-.5554-.8115-.2308-.2372-.5071-.4253-.8124-.553-.3053-.1278-.6333-.1925-.9642-.1903h-7.07843v-2.16595h7.08543c1.2405.00743 2.4281.50351 3.3053 1.38065.8771.8772 1.3732 2.0648 1.3806 3.3052.0076.9496-.2819 1.8777-.8281 2.6544-.5027.7432-1.2111 1.3237-2.0386 1.6705-.8194.2599-1.6745.3889-2.5341.3823h-4.247z"/>
              <path d="m20.2159 23.5215h-2.4775l-1.9111-3.3339c.7561-.0463 1.5019-.1988 2.2155-.453z"/>
              <path d="m25.3831 9.90975 1.4652.81405 1.4653-.81405v-1.72005l-1.4653-.84941-1.4652.84941z"/>
            </g>
          </svg>
          <span className="text-gray-100 font-medium tracking-wider text-xs sm:text-sm whitespace-nowrap">
            TRADE ON RAYDIUM
          </span>
          <ExternalLink className="w-4 h-4 text-blue-300/70 transition-transform group-hover:translate-x-1" />
        </div>
      </button>
    </div>
  );
};

export default TradeButtons;