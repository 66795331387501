// Layout.tsx
import React, { useContext, useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from './ui/navbar';
import Footer from './ui/footer';
import BackgroundScene from './threejs/backgroundScene';
import { LoadingContext } from '../App';

const Layout: React.FC = React.memo(() => {
  const context = useContext(LoadingContext);
  
  if (!context) {
    throw new Error('Layout must be used within a LoadingContext Provider');
  }

  const { setIsBackgroundLoaded } = context;

  // Memoize the callback to prevent unnecessary re-renders
  const handleBackgroundLoaded = useMemo(() => {
    return () => {
      console.log('Layout: Background scene loaded successfully');
      setIsBackgroundLoaded(true);
    };
  }, [setIsBackgroundLoaded]);

  // Memoize the background scene component
  const backgroundScene = useMemo(() => (
    <BackgroundScene onLoaded={handleBackgroundLoaded} />
  ), [handleBackgroundLoaded]);

  // Memoize the main content structure
  const mainContent = useMemo(() => (
    <div className="relative z-10 min-h-screen flex flex-col">
      <Navbar />
      <main className="flex-grow h-auto w-full max-w-7xl mx-auto  mt-10 lg:mt-16 sm:px-0 lg:px-8">
        <Outlet />
      </main>
      <Footer />
    </div>
  ), []);

  return (
    <div className="relative w-full min-h-screen">
      <div className="fixed inset-0 w-full h-full">
        {backgroundScene}
      </div>
      {mainContent}
    </div>
  );
});

Layout.displayName = 'Layout';

export default Layout;