import React, { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import CoinScene from './coin.jsx';

interface CoinContainerProps {
  onLoaded?: () => void;
}

const CoinContainer: React.FC<CoinContainerProps> = ({ onLoaded }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const rendererRef = useRef<THREE.WebGLRenderer | null>(null);
  const coinSceneRef = useRef<any>(null);
  const [isSceneLoaded, setIsSceneLoaded] = useState(false);

  useEffect(() => {
    if (!containerRef.current) {
      console.log('CoinContainer: Container ref not ready');
      return;
    }
    
    console.log('CoinContainer: Initializing...');

    rendererRef.current = new THREE.WebGLRenderer({ 
      antialias: true,
      alpha: true,
      premultipliedAlpha: false,
      preserveDrawingBuffer: false
    });
    
    console.log('CoinContainer: WebGL renderer created');
    
    const updateSize = () => {
      const container = containerRef.current;
      if (!container) return;
      
      const width = container.clientWidth;
      const height = Math.min(window.innerHeight * 0.9, width * 0.9);
      
      if (rendererRef.current) {
        rendererRef.current.setSize(width, height);
        console.log(`CoinContainer: Size updated to ${width}x${height}`);
      }
      
      container.style.height = `${height}px`;
      
      if (coinSceneRef.current?.camera) {
        coinSceneRef.current.camera.aspect = width / height;
        coinSceneRef.current.camera.updateProjectionMatrix();
      }
    };

    rendererRef.current.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    rendererRef.current.setClearColor(0x000000, 0);
    rendererRef.current.domElement.style.background = 'transparent';
    containerRef.current.appendChild(rendererRef.current.domElement);

    console.log('CoinContainer: Creating CoinScene instance...');
    coinSceneRef.current = new CoinScene(rendererRef.current, {
      onSceneLoaded: () => {
        console.log('CoinContainer: CoinScene loaded successfully');
        setIsSceneLoaded(true);
        onLoaded?.();
      }
    });

    if (coinSceneRef.current.scene) {
      coinSceneRef.current.scene.background = null;
    }

    updateSize();
    console.log('CoinContainer: Initial size set');

    const handleResize = () => {
      console.log('CoinContainer: Window resize event triggered');
      if (rendererRef.current && containerRef.current) {
        updateSize();
      }
    };

    const animate = () => {
      const animationId = requestAnimationFrame(animate);
      if (coinSceneRef.current && rendererRef.current) {
        rendererRef.current.clear();
        coinSceneRef.current.update(rendererRef.current);
      }
      return animationId;
    };

    window.addEventListener('resize', handleResize);
    const resizeObserver = new ResizeObserver(handleResize);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }
    
    console.log('CoinContainer: Starting animation loop');
    const animationId = animate();

    return () => {
      console.log('CoinContainer: Cleaning up...');
      window.removeEventListener('resize', handleResize);
      resizeObserver.disconnect();
      cancelAnimationFrame(animationId);
      
      if (rendererRef.current) {
        rendererRef.current.dispose();
      }
      if (containerRef.current && rendererRef.current) {
        containerRef.current.removeChild(rendererRef.current.domElement);
      }
      if (coinSceneRef.current?.cleanup) {
        coinSceneRef.current.cleanup();
      }
      console.log('CoinContainer: Cleanup complete');
    };
  }, [onLoaded]);

  return (
    <div 
      ref={containerRef}
      className="w-full mt-16 relative z-10 overflow-hidden"
    />
  );
};

export default CoinContainer;