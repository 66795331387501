import React, { useContext, useMemo } from 'react';
import CoinContainer from '../threejs/coinContainer';
import TradeButtons from '../ui/tradebuttons';
import { LoadingContext } from '../../App';

const Home: React.FC = React.memo(() => {
  const context = useContext(LoadingContext);
  
  if (!context) {
    throw new Error('Home must be used within a LoadingContext Provider');
  }

  const { setIsCoinLoaded } = context;

  const handleCoinLoaded = useMemo(() => {
    return () => {
      console.log('Home: Coin scene has finished loading');
      setIsCoinLoaded(true);
    };
  }, [setIsCoinLoaded]);

  const coinComponent = useMemo(() => (
    <CoinContainer onLoaded={handleCoinLoaded} />
  ), [handleCoinLoaded]);

  const tradeButtonsComponent = useMemo(() => (
    <TradeButtons />
  ), []);

  const heading = useMemo(() => (
    <h1 className="text-center text-white mt-4 md:mt-8 pb-8 md:pb-12 text-xs md:text-lg font-medium tracking-wide">
      YOUR DESTINY AWAITS
    </h1>
  ), []);

  return (
    <div className="relative flex-grow flex flex-col w-full items-center justify-center">
      <div className="relative w-full max-w-7xl mx-auto min-h-screen h-auto py-8 px-4">
        {/* SVG Border Container */}
        <div className="relative w-full h-full">
          {/* Background SVG Border */}
          <svg 
            className="absolute inset-0 w-full h-full" 
            preserveAspectRatio="none" 
            viewBox="0 0 1000 1000"
          >
            {/* Main border */}
            <rect
              x="1" y="1"
              width="998" height="998"
              rx="16"
              className="stroke-current text-purple-500/20 fill-none"
              strokeWidth="1"
            />
            <rect
              x="2" y="2"
              width="996" height="996"
              rx="15"
              className="stroke-current text-fuchsia-500/10 fill-none"
              strokeWidth="0.5"
            />
            {/* Corner Accents - Top Left */}
            <path 
              d="M 2 32 L 2 16 Q 2 2 16 2 L 32 2" 
              className="stroke-current text-purple-400/30 fill-none" 
              strokeWidth="3"
            />
            {/* Top Right */}
            <path 
              d="M 968 2 L 984 2 Q 998 2 998 16 L 998 32" 
              className="stroke-current text-purple-400/30 fill-none" 
              strokeWidth="3"
            />
            {/* Bottom Left */}
            <path 
              d="M 2 968 L 2 984 Q 2 998 16 998 L 32 998" 
              className="stroke-current text-purple-400/30 fill-none" 
              strokeWidth="3"
            />
            {/* Bottom Right */}
            <path 
              d="M 968 998 L 984 998 Q 998 998 998 984 L 998 968" 
              className="stroke-current text-purple-400/30 fill-none" 
              strokeWidth="3"
            />
          </svg>

          {/* Content Container */}
          <div className="relative flex flex-col items-center w-full max-w-4xl mx-auto">
            <div className="w-full">
              {coinComponent}
            </div>
            <div className="w-full mt-8">
              {tradeButtonsComponent}
            </div>
            {heading}
          </div>
        </div>
      </div>
    </div>
  );
});

Home.displayName = 'Home';

export default Home;