import { useState, useEffect } from 'react';

// Extended Navigator interface
interface NavigatorWithMemory extends Navigator {
  deviceMemory?: number;
  gpu?: {
    requestAdapter?: () => Promise<any>;
  };
}

type PerformanceLevel = 'high' | 'medium' | 'low';

interface GPUCapabilities {
  hasWebGL2: boolean;
  hasWeakGPU: boolean;
  maxTextureSize: number;
  renderer: string;
}

export const useDevicePerformance = () => {
  const [performanceLevel, setPerformanceLevel] = useState<PerformanceLevel>('medium');

  useEffect(() => {
    const detectGPUCapabilities = (): GPUCapabilities => {
      const defaults = {
        hasWebGL2: false,
        hasWeakGPU: true,
        maxTextureSize: 2048,
        renderer: 'unknown'
      };

      try {
        const canvas = document.createElement('canvas');
        const gl = canvas.getContext('webgl2') || canvas.getContext('webgl');

        if (!gl) return defaults;

        const debugInfo = gl.getExtension('WEBGL_debug_renderer_info');
        const renderer = debugInfo 
          ? gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL).toLowerCase()
          : 'unknown';

        return {
          hasWebGL2: !!canvas.getContext('webgl2'),
          hasWeakGPU: renderer.includes('intel') || 
                     renderer.includes('microsoft basic') ||
                     renderer.includes('swiftshader'),
          maxTextureSize: gl.getParameter(gl.MAX_TEXTURE_SIZE),
          renderer: renderer
        };
      } catch (e) {
        console.warn('GPU detection failed:', e);
        return defaults;
      }
    };

    const determinePerformance = (): PerformanceLevel => {
      const navigatorExt = navigator as NavigatorWithMemory;
      
      // Device checks
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      const memory = navigatorExt.deviceMemory || 4;
      const cores = navigator.hardwareConcurrency || 2;
      const hasModernGPU = !!(navigatorExt.gpu?.requestAdapter);
      
      // Screen and resolution checks
      const resolution = window.screen.width * window.screen.height;
      const pixelRatio = window.devicePixelRatio || 1;
      const isHighRes = resolution * pixelRatio > 2073600; // 1920x1080
      
      // GPU capabilities
      const gpuCaps = detectGPUCapabilities();

      // Scoring system (0-100)
      let score = 50; // Start at medium

      // Memory score (0-20)
      score += Math.min(memory * 2, 20);

      // CPU score (0-20)
      score += Math.min(cores * 2, 20);

      // GPU score (0-30)
      if (!gpuCaps.hasWeakGPU) score += 15;
      if (gpuCaps.hasWebGL2) score += 10;
      if (hasModernGPU) score += 5;

      // Resolution penalty
      if (isHighRes) score -= 10;

      // Mobile penalty
      if (isMobile) score -= 20;

      // Performance level determination
      if (score >= 70) return 'high';
      if (score >= 40) return 'medium';
      return 'low';
    };

    // Debounced resize handler
    let resizeTimeout: number;
    const handleResize = () => {
      if (resizeTimeout) {
        window.cancelAnimationFrame(resizeTimeout);
      }
      resizeTimeout = window.requestAnimationFrame(() => {
        setPerformanceLevel(determinePerformance());
      });
    };

    // Initial determination
    setPerformanceLevel(determinePerformance());

    // Setup resize listener
    window.addEventListener('resize', handleResize, { passive: true });

    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
      if (resizeTimeout) {
        cancelAnimationFrame(resizeTimeout);
      }
    };
  }, []);

  return performanceLevel;
};

// Performance settings based on level
export const getPerformanceSettings = (level: PerformanceLevel) => ({
  high: {
    targetFPS: 60,
    pixelRatio: Math.min(window.devicePixelRatio, 2),
    antialias: true,
    shadowMapType: 'PCFSoft',
    textureSize: 2048,
    particleDensity: 1.0,
    maxLights: 8
  },
  medium: {
    targetFPS: 45,
    pixelRatio: Math.min(window.devicePixelRatio, 1.5),
    antialias: true,
    shadowMapType: 'PCF',
    textureSize: 1024,
    particleDensity: 0.7,
    maxLights: 4
  },
  low: {
    targetFPS: 30,
    pixelRatio: 1,
    antialias: false,
    shadowMapType: 'Basic',
    textureSize: 512,
    particleDensity: 0.4,
    maxLights: 2
  }
}[level]);