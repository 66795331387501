import React from 'react';

const KismetDAOLogo: React.FC = () => {
  return (
    <div className="font-sans text-white opacity flex flex-row items-center select-none cursor-default">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" className="w-5 h-5 mr-1 ">
        <path 
          d="M25 20 L75 20 L50 50 L75 80 L25 80 L50 50 Z" 
          stroke="currentColor" 
          strokeWidth="4" 
          fill="none" 
          strokeLinecap="round" 
          strokeLinejoin="round"
        />
        <line x1="25" y1="20" x2="75" y2="20" stroke="currentColor" strokeWidth="4" />
        <line x1="25" y1="80" x2="75" y2="80" stroke="currentColor" strokeWidth="4" />
      </svg>
      <span className="font-bold text-xl tracking-wider">KISMET</span>
      <span className="font-thin text-xl ml-1 tracking-tight">DAO</span>
    </div>
  );
};

export default KismetDAOLogo;