import * as THREE from 'three';

class SmokeBackground {
    constructor(options = {}) {
        const { onInitialized } = options;
        
        console.log('SmokeBackground: Initializing...');
        
        this.scene = new THREE.Scene();
        this.camera = new THREE.OrthographicCamera(-1, 1, 1, -1, 0.1, 10);
        this.geometry = new THREE.PlaneGeometry(2, 2);
        
        console.log('SmokeBackground: Setting up shader materials...');
        this.material = new THREE.ShaderMaterial({
            uniforms: {
                u_time: { value: 0.0 },
                u_resolution: { value: new THREE.Vector2(window.innerWidth, window.innerHeight) },
                u_baseColorBottom: { value: new THREE.Color(0.01, 0.01, 0.01) },
                u_baseColorTop: { value: new THREE.Color(0.04, 0.04, 0.045) },
                u_fireflyColor: { value: new THREE.Color(0.9, 0.7, 0.3) },
                u_opacity: { value: 1.0 },
                u_smokeColor: { value: new THREE.Color(0.06, 0.06, 0.07) },
                u_fireflyCount: { value: 75.0 }  // Increased firefly count
            },
            vertexShader: `
            varying vec2 vUv;
            
            void main() {
                vUv = uv;
                gl_Position = vec4(position, 1.0);
            }
        `,
        fragmentShader: `
            uniform float u_time;
            uniform vec2 u_resolution;
            uniform vec3 u_baseColorBottom;
            uniform vec3 u_baseColorTop;
            uniform vec3 u_fireflyColor;
            uniform vec3 u_smokeColor;
            uniform float u_opacity;
            uniform float u_fireflyCount;
            
            varying vec2 vUv;

            // Optimized random function
            float random(vec2 st) {
                return fract(sin(dot(st.xy, vec2(12.9898,78.233))) * 43758.5453123);
            }

            // Simplified noise function for static smoke
            float noise(vec2 p) {
                vec2 i = floor(p);
                vec2 f = fract(p);
                f = f * f * (3.0 - 2.0 * f);
                
                float a = random(i);
                float b = random(i + vec2(1.0, 0.0));
                float c = random(i + vec2(0.0, 1.0));
                float d = random(i + vec2(1.0, 1.0));
                
                return mix(
                    mix(a, b, f.x),
                    mix(c, d, f.x),
                    f.y
                );
            }

            // Static fbm for smoke
            float staticSmoke(vec2 st) {
                float value = 0.0;
                float amplitude = 0.5;
                float frequency = 1.0;
                
                // Reduced octaves for better performance
                for (int i = 0; i < 3; i++) {
                    value += amplitude * noise(st * frequency);
                    frequency *= 2.0;
                    amplitude *= 0.5;
                }
                
                return value;
            }

            vec4 firefly(vec2 st, vec2 position, float depth) {
                float dist = length(st - position);
                float size = mix(0.003, 0.006, random(position));
                float glow = exp(-dist * (3.0 / size));
                
                // Simplified pulse animation
                float pulse = 0.85 + 0.15 * sin(u_time * (0.5 + random(position)));
                
                float intensity = glow * pulse * (depth * 0.4 + 0.6);
                
                vec3 color = u_fireflyColor;
                return vec4(color * intensity, intensity);
            }

            vec4 fireflies(vec2 st, bool frontLayer) {
                vec4 result = vec4(0.0);
                
                for(float i = 0.0; i < u_fireflyCount; i++) {
                    float depth = random(vec2(i, 12.345));
                    bool isInFront = depth > 0.6;
                    
                    if (isInFront == frontLayer) {
                        // Simplified movement pattern
                        float timeScale = 0.1 + random(vec2(i, 0.123)) * 0.1;
                        float amplitude = 0.1 + random(vec2(i, 0.456)) * 0.05;
                        
                        vec2 fireflyPos = vec2(
                            random(vec2(i, 1.234)) + sin(u_time * timeScale + i) * amplitude,
                            random(vec2(i, 5.678)) + cos(u_time * timeScale + i) * amplitude
                        );
                        
                        fireflyPos = fract(fireflyPos);
                        result += firefly(st, fireflyPos, depth);
                    }
                }
                
                return result;
            }

            void main() {
                vec2 st = vUv;
                vec2 res = u_resolution.xy / min(u_resolution.x, u_resolution.y);
                st *= res;

                // Base gradient
                vec3 baseColor = mix(u_baseColorBottom, u_baseColorTop, st.y);
                
                // Static smoke effect
                float smokePattern = staticSmoke(st * 2.0);
                baseColor = mix(baseColor, u_smokeColor, smokePattern * 0.4);

                vec3 color = baseColor;

                // Add fireflies
                vec4 backFireflies = fireflies(st, false);
                color = mix(color, backFireflies.rgb, backFireflies.a);
                
                vec4 frontFireflies = fireflies(st, true);
                color = mix(color, frontFireflies.rgb, frontFireflies.a);

                gl_FragColor = vec4(color, u_opacity);
            }
        `
    });
    
    console.log('SmokeBackground: Creating mesh...');
    this.mesh = new THREE.Mesh(this.geometry, this.material);
    this.scene.add(this.mesh);
    this.camera.position.z = 1;
    
    this.clock = new THREE.Clock();
    this.resize(window.innerWidth, window.innerHeight);
    
    console.log('SmokeBackground: Initialization complete');
    setTimeout(() => {
        onInitialized?.();
    }, 0);
}

// ... rest of the methods remain the same
setBaseColorBottom(color) {
    this.material.uniforms.u_baseColorBottom.value = new THREE.Color(color);
}

setBaseColorTop(color) {
    this.material.uniforms.u_baseColorTop.value = new THREE.Color(color);
}

setSmokeColor(color) {
    this.material.uniforms.u_smokeColor.value = new THREE.Color(color);
}

setFireflyColor(color) {
    this.material.uniforms.u_fireflyColor.value = new THREE.Color(color);
}

setFireflyCount(count) {
    this.material.uniforms.u_fireflyCount.value = count;
}

setOpacity(opacity) {
    this.material.uniforms.u_opacity.value = opacity;
}

resize(width, height) {
    this.material.uniforms.u_resolution.value.set(width, height);
}

update(renderer) {
    this.material.uniforms.u_time.value = this.clock.getElapsedTime();
    renderer.autoClear = false;
    renderer.clearDepth();
    renderer.render(this.scene, this.camera);
}
}

export default SmokeBackground;