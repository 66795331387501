import React, { useEffect, useState } from 'react';
import KismetLogo from './kismetlogo';

interface LoadingScreenProps {
  progress?: number; // 0 to 100
  onLoadingComplete?: () => void;
}

const LoadingScreen: React.FC<LoadingScreenProps> = ({ 
  progress = 0,
  onLoadingComplete 
}) => {
  const [displayProgress, setDisplayProgress] = useState(0);
  const [opacity, setOpacity] = useState(1);
  const [show, setShow] = useState(true);

  // Smooth progress updates
  useEffect(() => {
    const interval = setInterval(() => {
      setDisplayProgress(prev => {
        if (prev < progress) {
          return Math.min(prev + 1, progress);
        }
        return prev;
      });
    }, 20);

    return () => clearInterval(interval);
  }, [progress]);

  // Handle completion with delay and fade
  useEffect(() => {
    if (progress >= 100 && displayProgress >= 100) {
      // Add delay before starting fade
      const fadeDelay = setTimeout(() => {
        setOpacity(0);
      }, 300);

      // Remove component after fade
      const removeDelay = setTimeout(() => {
        setShow(false);
        if (onLoadingComplete) {
          onLoadingComplete();
        }
      }, 800); // 300ms delay + 500ms fade

      return () => {
        clearTimeout(fadeDelay);
        clearTimeout(removeDelay);
      };
    }
  }, [progress, displayProgress, onLoadingComplete]);

  if (!show) return null;

  return (
    <div 
      className="fixed inset-0 bg-dark-500 flex items-center justify-center z-50 transition-opacity duration-500"
      style={{ opacity }}
    >
      <div className="flex flex-col items-center gap-6">
        <div className="flex-shrink-0 animate-pulse">
          <KismetLogo />
        </div>
        <div className="w-48 h-2 bg-gray-800 rounded-full overflow-hidden">
          <div 
            className="h-full bg-cyan-500 transition-all duration-300 ease-out"
            style={{ width: `${displayProgress}%` }}
          />
        </div>
        <div className="text-cyan-300 text-sm font-medium">
          {displayProgress.toFixed(0)}%
        </div>
      </div>
    </div>
  );
};

export default LoadingScreen;