import React, { lazy, Suspense, useState, createContext, useMemo, useCallback } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './components/pages/home';
import LoadingScreen from './components/ui/loadingscreen';

// Define context types
interface LoadingContextType {
  isBackgroundLoaded: boolean;
  setIsBackgroundLoaded: (loaded: boolean) => void;
  isCoinLoaded: boolean;
  setIsCoinLoaded: (loaded: boolean) => void;
  resetLoadingState: () => void;
}

export const LoadingContext = createContext<LoadingContextType | undefined>(undefined);

// Lazy load routes with loading indicators
const Protocol = lazy(() => import('./components/pages/protocol'));
const Privacy = lazy(() => import('./components/pages/privacy'));
const Terms = lazy(() => import('./components/pages/terms'));

// Component to handle route-specific loading states
const RouteWrapper: React.FC<{ element: React.ReactElement }> = ({ element }) => {
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  // Only show loading screen for home page
  if (!isHomePage) {
    return element;
  }

  return (
    <LoadingContext.Consumer>
      {(context) => {
        if (!context) return null;
        const { isBackgroundLoaded, isCoinLoaded } = context;
        const showLoading = !isBackgroundLoaded || !isCoinLoaded;
        const progress = ((isBackgroundLoaded ? 50 : 0) + (isCoinLoaded ? 50 : 0));

        return (
          <>
            {showLoading && <LoadingScreen progress={progress} />}
            {element}
          </>
        );
      }}
    </LoadingContext.Consumer>
  );
};

// Memoized route configurations
const routeConfig = [
  {
    path: 'protocol',
    component: Protocol,
  },
  {
    path: 'privacy',
    component: Privacy,
  },
  {
    path: 'terms',
    component: Terms,
  },
] as const;

const App: React.FC = () => {
  const [isBackgroundLoaded, setIsBackgroundLoaded] = useState(false);
  const [isCoinLoaded, setIsCoinLoaded] = useState(false);

  // Memoized context value
  const contextValue = useMemo(() => {
    const resetLoadingState = () => {
      setIsBackgroundLoaded(false);
      setIsCoinLoaded(false);
    };

    return {
      isBackgroundLoaded,
      setIsBackgroundLoaded,
      isCoinLoaded,
      setIsCoinLoaded,
      resetLoadingState,
    };
  }, [isBackgroundLoaded, isCoinLoaded]);

  // Memoized route elements
  const routes = useMemo(() => (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={
          <RouteWrapper element={<Home />} />
        } />

        {routeConfig.map(({ path, component: Component }) => (
          <Route
            key={path}
            path={path}
            element={
              <Suspense fallback={<LoadingScreen progress={100} />}>
                <RouteWrapper element={<Component />} />
              </Suspense>
            }
          />
        ))}

        <Route path="*" element={
          <div className="container mx-auto px-4 py-8">
            <h1 className="text-2xl font-bold text-primary">404 - Page Not Found</h1>
          </div>
        } />
      </Route>
    </Routes>
  ), []);

  return (
    <LoadingContext.Provider value={contextValue}>
      <BrowserRouter>
        {routes}
      </BrowserRouter>
    </LoadingContext.Provider>
  );
};

export default App;