import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

class CoinScene {
    constructor(renderer, { onSceneLoaded } = {}) {
        console.log('CoinScene: Initializing scene...');
        this.scene = new THREE.Scene();
        this.renderer = renderer;
        
        console.log('CoinScene: Configuring renderer settings...');
        this.renderer.shadowMap.enabled = true;
        this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
        this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
        this.renderer.toneMappingExposure = 1.2;
        this.renderer.outputEncoding = THREE.sRGBEncoding;

        this.rotationSpeed = 0;
        this.isDragging = false;
        this.time = 0;

        const initializeComponents = async () => {
            try {
                console.log('CoinScene: Setting up responsive camera...');
                this.setupResponsiveCamera();
                
                console.log('CoinScene: Creating noise texture...');
                this.createNoiseTexture();
                
                console.log('CoinScene: Setting up environment map...');
                this.setupEnvironmentMap();
                
                console.log('CoinScene: Setting up materials...');
                this.setupMaterials();
                
                console.log('CoinScene: Creating coin model...');
                this.createCoin();
                
                console.log('CoinScene: Setting up lighting...');
                this.setupSimplifiedLighting();
                
                console.log('CoinScene: Configuring controls...');
                this.setupControls();
                
                console.log('CoinScene: Setting up event listeners...');
                this.setupEventListeners();

                console.log('CoinScene: Adding window resize listener...');
                window.addEventListener('resize', this.onWindowResize.bind(this));

                console.log('CoinScene: Initialization complete!');
                onSceneLoaded?.();
            } catch (error) {
                console.error('CoinScene: Error during initialization:', error);
            }
        };

        // Start initialization
        initializeComponents();
    }

    onWindowResize() {
        // Update camera aspect ratio
        this.camera.aspect = window.innerWidth / window.innerHeight;
        this.camera.updateProjectionMatrix();

        // Recalculate camera position
        this.setupResponsiveCamera();

        // Update renderer size
        this.renderer.setSize(window.innerWidth, window.innerHeight);
    }

    createNoiseTexture() {
        const textureSize = 2048;  // Increased for even finer detail
        const data = new Uint8Array(textureSize * textureSize * 4);
        
        // Create noise layers
        const baseNoise = new Array(textureSize * textureSize);
        const scratchNoise = new Array(textureSize * textureSize);
        const wearNoise = new Array(textureSize * textureSize);
        const highlightNoise = new Array(textureSize * textureSize);
        
        // Generate base metal grain (subtle)
        for (let i = 0; i < baseNoise.length; i++) {
            const x = i % textureSize;
            const y = Math.floor(i / textureSize);
            
            // Layered noise for metal grain
            let noise = 1;
            // Fine grain
            noise *= 1 - (Math.random() * 0.03);
            // Medium grain
            noise *= 1 - (Math.sin(x/20) * Math.cos(y/20) * 0.02);
            // Large grain
            noise *= 1 - (Math.sin(x/100) * Math.cos(y/100) * 0.01);
            
            baseNoise[i] = noise;
        }
        
        // Generate natural wear patterns following coin relief
        for (let y = 0; y < textureSize; y++) {
            for (let x = 0; x < textureSize; x++) {
                const idx = y * textureSize + x;
                
                // Calculate distance from center for radial patterns
                const centerX = textureSize / 0.02;
                const centerY = textureSize / 0.02;
                const dx = x - centerX;
                const dy = y - centerY;
                const distanceFromCenter = Math.sqrt(dx * dx + dy * dy);
                const normalizedDistance = distanceFromCenter / (textureSize * 0.05);
                
                // High points wear (raised areas like letters and rim)
                const rimDistance = Math.abs(normalizedDistance - 0.8);
                const isNearRim = rimDistance < 0.1;
                
                // Create circular wear patterns that follow relief
                const angle = Math.atan2(dy, dx);
                const radialNoise = Math.sin(angle * 8) * 0.5 + 0.5;
                
                // Combine different wear factors
                let wear = 1;
                
                // Rim wear
                if (isNearRim) {
                    const rimWear = 1 - (0.1 - rimDistance) * 3;
                    wear *= rimWear;
                }
                
                // General surface wear (more in center and high points)
                const surfaceWear = Math.pow(1 - normalizedDistance, 0.5);
                wear *= 0.95 + surfaceWear * 0.05;
                
                // Add directional wear (as if from handling)
                const directionalWear = Math.pow(Math.abs(Math.sin(angle * 2 + normalizedDistance * 5)), 0.5);
                wear *= 0.97 + directionalWear * 0.03;
                
                // Add some randomness to wear
                wear *= 0.98 + Math.random() * 0.02;
                
                wearNoise[idx] = wear;
                
                // Generate highlights for raised areas
                const highlight = Math.pow(Math.max(0, 1 - normalizedDistance), 2);
                highlightNoise[idx] = 0.95 + highlight * 0.05;
            }
        }
        
        // Generate realistic scratches
        for (let i = 0; i < 400; i++) {
            // Different types of scratches
            const scratchType = Math.random();
            let length, width, intensity;
            
            if (scratchType < 0.7) {
                // Common small scratches
                length = Math.random() * textureSize * 0.1;
                width = 1 + Math.random();
                intensity = 0.95 + Math.random() * 0.04;
            } else if (scratchType < 0.9) {
                // Medium scratches
                length = Math.random() * textureSize * 0.3;
                width = 1.5 + Math.random() * 2;
                intensity = 0.93 + Math.random() * 0.05;
            } else {
                // Deep, long scratches (rare)
                length = Math.random() * textureSize * 0.5;
                width = 2 + Math.random() * 3;
                intensity = 0.90 + Math.random() * 0.05;
            }
            
            // Scratch starting point (weighted towards center and rim)
            const radius = Math.random() < 0.6 ? 
                         textureSize * (0.3 + Math.random() * 0.2) : // Center area
                         textureSize * (0.4 + Math.random() * 0.1);  // Rim area
            const angle = Math.random() * Math.PI * 2;
            const startX = textureSize/2 + Math.cos(angle) * radius;
            const startY = textureSize/2 + Math.sin(angle) * radius;
            
            // Scratch direction (tends to follow circular patterns)
            const scratchAngle = angle + (Math.random() - 0.5) * Math.PI * 0.5;
            
            // Draw scratch
            for (let j = 0; j < length; j++) {
                // Add some natural variation to the scratch path
                const wobble = Math.sin(j * 0.1) * width * 0.5;
                const x = Math.floor(startX + Math.cos(scratchAngle) * j + wobble);
                const y = Math.floor(startY + Math.sin(scratchAngle) * j + wobble);
                
                if (x >= 0 && x < textureSize && y >= 0 && y < textureSize) {
                    // Variable width along scratch length
                    const currentWidth = width * (1 - Math.random() * 0.2);
                    
                    for (let w = -currentWidth; w <= currentWidth; w++) {
                        const wx = Math.floor(x + w);
                        if (wx >= 0 && wx < textureSize) {
                            const idx = y * textureSize + wx;
                            const falloff = Math.pow(1 - Math.abs(w) / currentWidth, 0.5);
                            const currentIntensity = intensity + (1 - intensity) * (1 - falloff);
                            
                            if (!scratchNoise[idx] || currentIntensity < scratchNoise[idx]) {
                                scratchNoise[idx] = currentIntensity;
                            }
                        }
                    }
                }
            }
        }
        
        // Combine all layers into final texture
        for (let i = 0; i < data.length; i += 4) {
            const idx = i / 4;
            
            let value = baseNoise[idx] || 1;
            value *= wearNoise[idx] || 1;
            value *= scratchNoise[idx] || 1;
            value *= highlightNoise[idx] || 1;
            
            // Add ultra-fine grain noise
            value *= (1 - (Math.random() * 0.01));
            
            const pixelValue = Math.floor(value * 255);
            
            data[i] = pixelValue;     // R
            data[i + 1] = pixelValue; // G
            data[i + 2] = pixelValue; // B
            data[i + 3] = 255;        // A
        }

        this.noiseTexture = new THREE.DataTexture(data, textureSize, textureSize, THREE.RGBAFormat);
        this.noiseTexture.wrapS = THREE.RepeatWrapping;
        this.noiseTexture.wrapT = THREE.RepeatWrapping;
        this.noiseTexture.repeat.set(4, 4);  // Increased repeat for more detail
        this.noiseTexture.needsUpdate = true;
    }

    setupMaterials() {
        this.goldMaterial = new THREE.MeshPhysicalMaterial({
            color: new THREE.Color(0xffd700).multiplyScalar(0.95), // Slightly darker gold
            metalness: 0.85,
            roughness: 0.35,
            roughnessMap: this.noiseTexture,
            envMapIntensity: 1.3,
            clearcoat: 0.3,
            clearcoatRoughness: 0.4,
            reflectivity: 0.8,
            sheenColor: new THREE.Color(0xffd700),
            sheen: 0.1
        });

        this.textMaterial = new THREE.MeshPhysicalMaterial({
            color: new THREE.Color(0xffd700).multiplyScalar(0.98),
            metalness: 0.9,
            roughness: 0.3,
            roughnessMap: this.noiseTexture,
            envMapIntensity: 1.4,
            clearcoat: 0.4,
            clearcoatRoughness: 0.3,
            reflectivity: 0.9,
            sheenColor: new THREE.Color(0xffd700),
            sheen: 0.15
        });
    }

    setupResponsiveCamera() {
        const aspectRatio = window.innerWidth / window.innerHeight;
        this.camera = new THREE.PerspectiveCamera(75, aspectRatio, 0.1, 1000);
        // Set fixed camera distance
        this.camera.position.set(0, 0, 3.5);
        this.camera.lookAt(0, 0, 0);
    }




    createLetterShape(paths) {
        const shape = new THREE.Shape();
        paths.forEach((point, index) => {
            if (index === 0) {
                shape.moveTo(point[0], point[1]);
            } else {
                shape.lineTo(point[0], point[1]);
            }
        });
        shape.closePath();
        return shape;
    }

    createCoin() {
        // Coin geometry
        const coinRadius = 2;
        const coinThickness = 0.15;
        const segments = 128;
        const coinGeometry = new THREE.CylinderGeometry(coinRadius, coinRadius, coinThickness, segments);
        this.coin = new THREE.Mesh(coinGeometry, this.goldMaterial);
        this.coin.castShadow = true;
        this.coin.receiveShadow = true;

        // Define letters
        const letters = {
            K: {
                shapes: [
                    [[0.3, 0], [0.5, 0], [0.5, 1], [0.3, 1]],
                    [[0.5, 0.5], [0.6, 0.5], [1.0, 1], [0.8, 1]],
                    [[0.5, 0.5], [0.6, 0.5], [1.0, 0], [0.8, 0]]
                ]
            },
            I: {
                shapes: [
                    [[0.4, 0], [0.6, 0], [0.6, 1], [0.4, 1]]
                ]
            },
            S: {
                "shapes": [
                    [[0.2, 1.0], [0.8, 0.0]],      // Diagonal line from top-left to bottom-right
                    [[0.8, 1.0], [0.2, 0.0]],      // Diagonal line from top-right to bottom-left
                    [[0.2, 1.03], [0.8, 1.03]],    // Top horizontal line, moved up slightly
                    [[0.2, -0.03], [0.8, -0.03]]   // Bottom horizontal line, moved down slightly
                ]
            },
            M: {
                shapes: [
                    [[0.2, 0], [0.35, 0], [0.35, 1], [0.2, 1]],
                    [[0.65, 0], [0.8, 0], [0.8, 1], [0.65, 1]],
                    [[0.35, 1], [0.45, 1], [0.525, 0.45], [0.475, 0.45]],
                    [[0.475, 0.45], [0.525, 0.45], [0.65, 1], [0.55, 1]]
                ]
            },
            E: {
                shapes: [
                    [
                        [0.3, 0], [0.9, 0], [0.9, 0.2], [0.5, 0.2],
                        [0.5, 0.4], [0.8, 0.4], [0.8, 0.6], [0.5, 0.6],
                        [0.5, 0.8], [0.9, 0.8], [0.9, 1], [0.3, 1]
                    ]
                ]
            },
            T: {
                shapes: [
                    [
                        [0.2, 0.8], [0.4, 0.8], [0.4, 0], [0.6, 0],
                        [0.6, 0.8], [0.8, 0.8], [0.8, 1], [0.2, 1]
                    ]
                ]
            }
        };

        // Create text
        const word = "KISMET";
        const letterContainer = {
            width: 1,
            spacing: 0.2
        };

        const textGroup = new THREE.Group();
        let currentX = 0;

        word.split('').forEach((char) => {
            if (letters[char]) {
                const letterGroup = new THREE.Group();
                
                letters[char].shapes.forEach(shapePaths => {
                    const shape = this.createLetterShape(shapePaths);
                    const geometry = new THREE.ExtrudeGeometry(shape, {
                        depth: 0.05,
                        bevelEnabled: true,
                        bevelThickness: 0.06,
                        bevelSize: 0.02,
                        bevelSegments: 4
                    });
                    
                    const mesh = new THREE.Mesh(geometry, this.textMaterial);
                    mesh.castShadow = true;
                    mesh.receiveShadow = true;
                    letterGroup.add(mesh);
                });

                letterGroup.position.x = currentX;
                textGroup.add(letterGroup);
                
                currentX += letterContainer.width + letterContainer.spacing;
            }
        });

        const totalWidth = (word.length * letterContainer.width) + 
                          ((word.length - 1) * letterContainer.spacing);

        textGroup.position.x = -totalWidth / 2;
        textGroup.position.z = 0;

        // Create front and back text
        const frontText = textGroup.clone();
        const backText = textGroup.clone();

        const textScale = 0.35;
        frontText.scale.set(textScale, textScale, textScale);
        backText.scale.set(textScale, textScale, textScale);

        frontText.position.set(-1.25, coinThickness/2 + 0.01, 0.1);
        frontText.rotation.x = -Math.PI / 2;

        backText.position.set(1.25, -coinThickness/2 - 0.01, 0.1);
        backText.rotation.x = Math.PI / 2;
        backText.rotation.z = Math.PI;

        this.coin.add(frontText);
        this.coin.add(backText);

        // Add serrated edge
        const edgeDetail = new THREE.Group();
        const serrationCount = 360;
        const serrationWidth = (2 * Math.PI * coinRadius) / serrationCount;
        const serrationGeometry = new THREE.BoxGeometry(serrationWidth, coinThickness + 0.001, 0.05);

        for (let i = 0; i < serrationCount; i++) {
            const angle = (i / serrationCount) * Math.PI * 2;
            const serration = new THREE.Mesh(serrationGeometry, this.textMaterial);
            serration.castShadow = true;
            serration.receiveShadow = true;
            
            serration.position.x = Math.cos(angle) * (coinRadius + 0.01);
            serration.position.z = Math.sin(angle) * (coinRadius + 0.01);
            serration.rotation.y = angle;
            
            edgeDetail.add(serration);
        }
        this.coin.add(edgeDetail);

        // Add decorative rings
        const ringGeometry = new THREE.TorusGeometry(
            coinRadius + 0,
            0.02,
            16,
            180
        );

        const frontRing = new THREE.Mesh(ringGeometry, this.textMaterial);
        frontRing.castShadow = true;
        frontRing.receiveShadow = true;
        frontRing.position.y = coinThickness / 2;
        frontRing.rotation.x = Math.PI / 2;
        this.coin.add(frontRing);

        const backRing = new THREE.Mesh(ringGeometry, this.textMaterial);
        backRing.castShadow = true;
        backRing.receiveShadow = true;
        backRing.position.y = -coinThickness / 2;
        backRing.rotation.x = Math.PI / 2;
        this.coin.add(backRing);


                // After creating the coin, set its initial rotation to show front face
        this.coin.rotation.set(8, 0, 0);
        this.scene.add(this.coin);

    }


    setupEnvironmentMap() {
        // Create dynamic environment map
        this.cubeRenderTarget = new THREE.WebGLCubeRenderTarget(1024, {
            generateMipmaps: true,
            minFilter: THREE.LinearMipmapLinearFilter,
            encoding: THREE.sRGBEncoding
        });
        
        this.cubeCamera = new THREE.CubeCamera(0.1, 100, this.cubeRenderTarget);
        
        // Create additional reflection probe scene
        this.probeScene = new THREE.Scene();
        
        // Add gradient background to reflection scene
        const gradientTexture = this.createGradientTexture();
        this.probeScene.background = gradientTexture;
        
        // Add some objects to reflection scene for interesting reflections
        const reflectionSphere = new THREE.Mesh(
            new THREE.SphereGeometry(10, 32, 32),
            new THREE.MeshStandardMaterial({ 
                color: 0xffffff,
                metalness: 0.5,
                roughness: 0.1
            })
        );
        reflectionSphere.position.set(0, 0, -15);
        this.probeScene.add(reflectionSphere);
        
        // Set environment map
        this.scene.environment = this.cubeRenderTarget.texture;
    }

    createGradientTexture() {
        const canvas = document.createElement('canvas');
        canvas.width = 2;
        canvas.height = 2;
        
        const context = canvas.getContext('2d');
        const gradient = context.createLinearGradient(0, 0, 0, canvas.height);
        gradient.addColorStop(0, '#666666');
        gradient.addColorStop(1, '#000000');
        
        context.fillStyle = gradient;
        context.fillRect(0, 0, canvas.width, canvas.height);
        
        const texture = new THREE.CanvasTexture(canvas);
        texture.mapping = THREE.EquirectangularReflectionMapping;
        
        return texture;
    }




    setupSimplifiedLighting() {
        // Main directional light
        const mainLight = new THREE.DirectionalLight(0xffffff, 1.0);
        mainLight.position.set(5, 5, 5);
        mainLight.castShadow = true;
        mainLight.shadow.mapSize.set(2048, 2048);
        mainLight.shadow.normalBias = 0.02;
        mainLight.shadow.bias = -0.001;
        
        // Fill light from opposite direction
        const fillLight = new THREE.DirectionalLight(0xffd700, 0.5);
        fillLight.position.set(-5, 3, -5);
        
        // Top light for better visibility
        const topLight = new THREE.DirectionalLight(0xffffff, 0.3);
        topLight.position.set(0, 10, 0);
        
        // Ambient light for base illumination
        const ambientLight = new THREE.AmbientLight(0xffffff, 0.4);

        // Area light for soft front illumination
        const areaLight = new THREE.RectAreaLight(0xffd700, 1, 4, 4);
        areaLight.position.set(0, 0, 5);
        areaLight.lookAt(0, 0, 0);

        // Add all lights to scene
        this.scene.add(mainLight);
        this.scene.add(fillLight);
        this.scene.add(topLight);
        this.scene.add(ambientLight);
        this.scene.add(areaLight);

        // Store main light for potential updates
        this.mainLight = mainLight;
    }




    setupControls() {
        this.controls = new OrbitControls(this.camera, this.renderer.domElement);
        this.controls.enableDamping = true;
        this.controls.dampingFactor = 0.05;
        
        // Disable zoom and pan, only allow rotation
        this.controls.enableZoom = false;
        this.controls.enablePan = false;
        this.controls.enableRotate = true;
        
        // Set rotation limits to prevent awkward angles
        //this.controls.maxPolarAngle = Math.PI / 1.5;
        //this.controls.minPolarAngle = Math.PI / 3;
        
        // Important: Set the rotation pivot point to the center
        this.controls.target.set(0, 0, 0);
        
        // Optional: Add rotation constraints if needed
        // this.controls.minAzimuthAngle = -Math.PI / 2; // Limit left rotation
        // this.controls.maxAzimuthAngle = Math.PI / 2;  // Limit right rotation
        
        this.controls.update();
    }



    setupEventListeners() {
        this.renderer.domElement.addEventListener('mousedown', () => {
            this.isDragging = true;
        });

        this.renderer.domElement.addEventListener('mouseup', () => {
            this.isDragging = false;
        });

        this.renderer.domElement.addEventListener('mouseleave', () => {
            this.isDragging = false;
        });
    }

    update(renderer) {
        this.time += 0.016;

        // Animate noise texture
        if (this.noiseTexture) {
            this.noiseTexture.offset.x = Math.sin(this.time * 0.5) * 0.02;
            this.noiseTexture.offset.y = Math.cos(this.time * 0.5) * 0.02;
        }

        // Subtle floating animation
        this.coin.position.y = Math.sin(Date.now() * 0.0015) * 0.1;
        // Rotate around Z axis for horizontal wheel-like rolling
        this.coin.rotation.z += 0.00;  // Adjust speed by changing this value

        // Update environment map
        if (this.coin.material) {
            this.coin.visible = false;
            this.cubeCamera.update(renderer, this.scene);
            this.coin.visible = true;
        }

        this.controls.update();
        renderer.render(this.scene, this.camera);
    }
}

export default CoinScene;