// src/components/threejs/BackgroundScene.tsx
import React, { useEffect, useRef, useState } from 'react';
import SmokeBackground from './smoke';
import * as THREE from 'three';
import { useDevicePerformance } from '../hooks/useDevicePerformance';

interface BackgroundSceneProps {
  onLoaded?: () => void;
}

const BackgroundScene: React.FC<BackgroundSceneProps> = ({ onLoaded }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const backgroundRef = useRef<SmokeBackground | null>(null);
  const rendererRef = useRef<THREE.WebGLRenderer | null>(null);
  const performanceLevel = useDevicePerformance();
  const [fps, setFps] = useState<number>(60);
  const frameTimesRef = useRef<number[]>([]);
  const lastFrameTimeRef = useRef<number>(performance.now());

  useEffect(() => {
    if (!containerRef.current) {
      console.log('BackgroundScene: Container ref not ready');
      return;
    }

    console.log('BackgroundScene: Initializing...');

    const settings = {
      high: {
        particleDensity: 30,
        fireflyCount: 15,
        pixelRatio: Math.min(window.devicePixelRatio, 2),
        antialias: true
      },
      medium: {
        particleDensity: 20,
        fireflyCount: 10,
        pixelRatio: Math.min(window.devicePixelRatio, 1.5),
        antialias: true
      },
      low: {
        particleDensity: 10,
        fireflyCount: 5,
        pixelRatio: 1,
        antialias: false
      }
    };

    const currentSettings = settings[performanceLevel];

    try {
      console.log('BackgroundScene: Creating SmokeBackground...');
      backgroundRef.current = new SmokeBackground({
        onInitialized: () => {
          console.log('BackgroundScene: Smoke background initialized');
          onLoaded?.();
        }
      });
      backgroundRef.current.setFireflyCount(currentSettings.fireflyCount);

      console.log('BackgroundScene: Setting up renderer...');
      rendererRef.current = new THREE.WebGLRenderer({ 
        antialias: currentSettings.antialias,
        alpha: true,
        powerPreference: 'high-performance'
      });
      
      rendererRef.current.setSize(window.innerWidth, window.innerHeight);
      rendererRef.current.setPixelRatio(currentSettings.pixelRatio);
      containerRef.current.appendChild(rendererRef.current.domElement);
      
      console.log('BackgroundScene: Setup complete');
    } catch (error) {
      console.error('BackgroundScene: Error during initialization:', error);
    }

    // FPS monitoring and dynamic quality adjustment
    const updateFPS = () => {
      const now = performance.now();
      const deltaTime = now - lastFrameTimeRef.current;
      lastFrameTimeRef.current = now;

      frameTimesRef.current.push(deltaTime);
      if (frameTimesRef.current.length > 60) {
        frameTimesRef.current.shift();
      }

      const averageDelta = frameTimesRef.current.reduce((a, b) => a + b, 0) / frameTimesRef.current.length;
      const currentFPS = 1000 / averageDelta;
      setFps(Math.round(currentFPS));

      // Dynamic quality adjustment
      if (currentFPS < 30 && backgroundRef.current) {
        backgroundRef.current.setFireflyCount(Math.max(3, currentSettings.fireflyCount - 3));
      }
    };

    const handleResize = () => {
      if (!rendererRef.current || !backgroundRef.current) return;
      
      const width = window.innerWidth;
      const height = window.innerHeight;
      
      rendererRef.current.setSize(width, height);
      backgroundRef.current.resize(width, height);
    };

    // Optimized animation loop with RAF throttling
    let lastFrame = 0;
    const targetFPS = performanceLevel === 'low' ? 30 : 60;
    const frameInterval = 1000 / targetFPS;

    const animate = (timestamp: number) => {
      const animationId = requestAnimationFrame(animate);
      
      const delta = timestamp - lastFrame;
      if (delta < frameInterval) return;
      
      lastFrame = timestamp - (delta % frameInterval);
      
      if (backgroundRef.current && rendererRef.current) {
        backgroundRef.current.update(rendererRef.current);
        updateFPS();
      }

      return animationId;
    };

    window.addEventListener('resize', handleResize);
    const animationId = requestAnimationFrame(animate);

    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
      cancelAnimationFrame(animationId);
      
      if (rendererRef.current) {
        rendererRef.current.dispose();
      }
      
      if (containerRef.current && rendererRef.current) {
        containerRef.current.removeChild(rendererRef.current.domElement);
      }
    };
  }, [performanceLevel, onLoaded]);

  return (
    <>
      <div 
        ref={containerRef}
        className="fixed inset-0 z-0"
      />
      {process.env.NODE_ENV === 'development' && (
        <div className="fixed bottom-2 right-2 bg-black/50 text-white px-2 py-1 z-100 rounded text-sm">
          {fps} FPS | {performanceLevel}
        </div>
      )}
    </>
  );
};

export default BackgroundScene;